<template>
  <div>
    <y-card class-name="filter-container">
      <el-form ref="searchForm" :inline="true" :model="queryParams">
        <el-form-item>
          <el-cascader
            v-model="elCascader.choiceData"
            :options="elCascader.options"
            :props="elCascader.props"
            size="small"
            placeholder="学校/录像机"
            style="width:450px"
            filterable
            collapse-tags
            clearable
          >
            <template slot-scope="{ node, data }">
              <span>{{ data.name }}</span>
              <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item prop="deviceName">
          <el-input v-model="queryParams.deviceName" placeholder="摄像头名称" size="small" />
        </el-form-item>
        <el-form-item prop="online">
          <el-select
            v-model="queryParams.online"
            placeholder="在线状态"
            size="small"
            clearable
          >
            <el-option label="离线" :value="0" />
            <el-option label="在线" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="search">查询</el-button>
          <el-button size="small" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </y-card>
    <!--table-->
    <y-card>
      <table-common
        ref="table"
        url="/camera"
        :where="queryParams"
        :default-tool-bar="false"
        :default-operating="false"
      >
        <el-table-column prop="deviceName" label="摄像头名称" width="280" />
        <el-table-column prop="cameraType" label="摄像头类型" width="100" :formatter="cameraTypeFormatter" />
        <el-table-column prop="transType" label="传输协议" width="80" :formatter="transTypeFormatter" />
        <el-table-column prop="treatyType" label="接入协议" width="80" :formatter="treatyTypeFormatter" />
        <el-table-column prop="chanNum" label="通道号" width="80" />
        <el-table-column prop="resourceName" label="所属录像机" />
        <el-table-column prop="companyName" label="所属学校" />
        <el-table-column prop="collectTime" label="最近采集时间" :formatter="collectTimeFormatter" width="160" />
        <el-table-column prop="online" label="在线状态" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.online ===0"><span class="badge offline" />{{ '离线' }}</div>
            <div v-if="scope.row.online ===1"><span class="badge online" />{{ '在线' }}</div>
          </template>
        </el-table-column>
      </table-common>
    </y-card>
  </div>
</template>

<script>
import { loadCompanyResourceTree } from '@/api/choice/choice.js'
import { toTree } from '@/libs/tree'
import BaseNotify from '@/libs/mixin/BaseNotify'
import { mapGetters } from 'vuex'
export default {
  name: 'user',
  mixins: [BaseNotify],
  data() {
    return {
      queryParams: {
        deviceName: ''
      },
      cameraType: {
        0: '枪机',
        1: '半球',
        2: '快球',
        3: '带云台枪机'
      },
      transType: {
        0: 'UDP',
        1: 'TCP'
      },
      treatyType: {
        hiksdk_net: '海康SDK',
        gb_reg: 'GB/T28181',
        ehome_reg: 'EHOME',
        onvif_net: 'ONVIF',
        dhsdk_net: '大华SDK',
        bi_reg: 'B接口协议',
        ezviz_net: '萤石协议'
      },
      elCascader: {
        options: [],
        props: { multiple: true, value: 'id', label: 'name' },
        // 选中的数据
        choiceData: []
      },
      companyIds: [],
      resourceIndexCodes: []
    }
  },
  computed: {
    ...mapGetters([
      'hasRole'
    ])
  },
  mounted() {
    loadCompanyResourceTree().then(res => {
      this.elCascader.options = toTree(res.data)
    })
  },
  methods: {
    search() {
      console.log(this.elCascader.choiceData)
      // 遍历赋值
      this.elCascader.choiceData.forEach(element => {
        this.companyIds.push(element[0])
        this.resourceIndexCodes.push(element[1])
      })
      this.queryParams.companyIds = this.companyIds.join(',')
      this.queryParams.resourceIndexCodes = this.resourceIndexCodes.join(',')
      this.$refs.table.reload(true)
    },
    reset() {
      this.companyIds = []
      this.resourceIndexCodes = []
      this.queryParams.companyIds = ''
      this.queryParams.resourceIndexCodes = ''
      this.elCascader.choiceData = []
      this.$refs.searchForm.resetFields()
    },
    reload() {
      this.$refs.table.reload()
    },
    collectTimeFormatter(row, column, cellValue, index) {
      return cellValue || '-'
    },
    cameraTypeFormatter(row, column, cellValue, index) {
      return this.cameraType[cellValue]
    },
    transTypeFormatter(row, column, cellValue, index) {
      return this.transType[cellValue]
    },
    treatyTypeFormatter(row, column, cellValue, index) {
      return this.treatyType[cellValue]
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    margin-right: 5px;

}

.offline {
  background-color: #909399;
}

.online {
  background-color: #67c23a;
}

</style>
