<template>
  <el-dialog
    title="视频回放"
    :visible.sync="dialogVisible"
    width="60%"
    @close="close"
  >
    <el-form :inline="true">
      <el-form-item label="回放时间">
        <el-date-picker
          v-model="timeRange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          value-format="yyyy-MM-ddTHH:mm:ssZ"
          size="small"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="initVideo()">回放</el-button>
        <el-button size="small" @click="fast">快放</el-button>
        <el-button size="small" @click="slow">慢放</el-button>
        当前速度 x{{ speed }}
      </el-form-item>
    </el-form>
    <div v-loading="loading" class="content">
      <div id="replayer" class="player" />
    </div>
  </el-dialog>
</template>

<script>
import { cameraReplay } from '@/api/camera/camera'
import BasicDialog from '@/libs/mixin/BasicDialog'
import BaseNotify from '@/libs/mixin/BaseNotify'
export default {
  mixins: [BasicDialog, BaseNotify],
  props: {
    deviceIndexCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      timeRange: [],
      speed: 1,
      player: null
    }
  },
  methods: {
    init(val) {
    },
    initVideo() {
      if (!this.timeRange || this.timeRange.length !== 2) {
        this.warningTip('请选择回放时间段')
        return
      }
      // eslint-disable-next-line no-undef
      this.player = new JSPlugin({
        // 需要英文字母开头 必填
        szId: 'replayer',
        // 必填,引用H5player.min.js的js相对路径
        szBasePath: '/static/js/',

        // 分屏播放，默认最大分屏4*4
        iMaxSplit: 1,
        iCurrentSplit: 1,

        // 样式
        oStyle: {
          border: '#343434',
          borderSelect: '#FFCC00',
          background: '#000'
        }
      })
      // 事件回调绑定
      this.player.JS_SetWindowControlCallback({
        windowEventSelect: (iWndIndex) => { // 插件选中窗口回调
          // console.log('windowSelect callback: ', iWndIndex)
          this.$emit('select', this.index)
        },
        pluginErrorHandler: (index, iErrorCode, oError) => {
          // 插件错误回调
          // do you want...
          // 取流失败，流中断等错误都会触发该回调函数，请自行对照错误码表进行判断。
          // 业务上层可在此做一些个性化操作，如：个性化错误信息展示，重新取流等。
          this.warningTip('取流失败')
          this.loading = false
        },
        performanceLack: () => { // 性能不足回调
          console.log('performanceLack callback: ')
        }
      })
      this.player.JS_SetConnectTimeOut(0, 30).then(() => {
        cameraReplay(this.deviceIndexCode, this.timeRange[0], this.timeRange[1]).then(res => {
          this.replay(res.data.url)
        })
      })
      // this.replay('ws://115.239.172.66:559/openUrl/EaCSqzK?beginTime=20221216T081000&endTime=20221216T091700')
    },
    async replay(url) {
      await this.player.JS_Stop(0)
      this.loading = true
      const param = {
        playURL: url,
        mode: 1
      }
      this.player.JS_Play(url, param, 0, this.timeRange[0], this.timeRange[1]).then(() => {
        console.log('播放成功')
        this.loading = false
      }).catch(err => {
        console.log('播放失败', err)
        this.warningTip('播放失败')
        this.loading = false
      })
    },
    close() {
      if (this.player) {
        this.player.JS_Stop(0)
      }
      this.visible = false
    },
    fast() {
      if (!this.player) {
        return
      }
      if (this.speed === 8) {
        return
      }
      this.speed = this.speed * 2
      this.player.JS_Fast(0)
    },
    slow() {
      if (!this.player) {
        return
      }
      if (this.speed === 1) {
        return
      }
      this.speed = this.speed / 2
      this.player.JS_Slow(0)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 560px;
  border: 6px solid #0004e7;
  border-radius: 5px;
  background: #000000;
  box-sizing: border-box;
}
.player {
  height: 100%;
  width: 100%;
}
</style>
