import request from '@/axios'

export const dataSync = () => {
  return new Promise((resolve, reject) => {
    request({
      url: '/vcr/dataSync',
      method: 'get'
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

export const onlineStatusSync = () => {
  return new Promise((resolve, reject) => {
    request({
      url: '/vcr/onlineStatusSync',
      method: 'get'
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

// ---------------------------------------------------------------

// export const addUser = (user) => {
//   return new Promise((resolve, reject) => {
//     request({
//       url: '/system/user',
//       method: 'post',
//       data: user
//     }).then(res => {
//       resolve(res)
//     }).catch(error => {
//       reject(error)
//     })
//   })
// }

// export const getUser = (id) => {
//   return new Promise((resolve, reject) => {
//     request({
//       url: '/system/user/' + id,
//       method: 'get'
//     }).then(res => {
//       resolve(res)
//     }).catch(error => {
//       reject(error)
//     })
//   })
// }

// export const updateUser = (user) => {
//   return new Promise((resolve, reject) => {
//     request({
//       url: '/system/user/update',
//       method: 'post',
//       data: user
//     }).then(res => {
//       resolve(res)
//     }).catch(error => {
//       reject(error)
//     })
//   })
// }

// export const deleteUser = (id) => {
//   return new Promise((resolve, reject) => {
//     request({
//       url: '/system/user/delete',
//       method: 'get',
//       params: { id: id }
//     }).then(res => {
//       resolve(res)
//     }).catch(error => {
//       reject(error)
//     })
//   })
// }

// /**
//  * 修改密码
//  * @param {登录用户id} userId
//  * @param {密码修改对象} password
//  * @returns
//  */
// export const editPassword = (userId, password) => {
//   return new Promise((resolve, reject) => {
//     request({
//       url: '/user/' + userId + '/password',
//       method: 'post',
//       data: password
//     }).then(res => {
//       resolve(res)
//     }).catch(error => {
//       reject(error)
//     })
//   })
// }
