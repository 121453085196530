<template>
  <div>
    <y-card class-name="filter-container">
      <el-form ref="searchForm" :inline="true" :model="queryParams">
        <el-form-item prop="companyName">
          <el-input v-model="queryParams.companyName" placeholder="学校名称" size="small" />
        </el-form-item>
        <el-form-item prop="resourceName">
          <el-input v-model="queryParams.resourceName" placeholder="录像机名称" size="small" />
        </el-form-item>
        <el-form-item prop="online">
          <el-select
            v-model="queryParams.online"
            placeholder="在线状态"
            size="small"
            clearable
          >
            <el-option label="离线" :value="0" />
            <el-option label="在线" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="search">查询</el-button>
          <el-button size="small" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </y-card>

    <!--table-->
    <y-card>
      <table-common
        ref="table"
        url="/vcr"
        :where="queryParams"
        :default-operating="false"
      >
        <template #toolbar>
          <el-button
            type="primary"
            size="small"
            :loading="loadingForDataSync"
            @click="dataSyncClick()"
          >数据全量同步</el-button>

          <el-button
            type="primary"
            size="small"
            :loading="loadingForOnlineStatusSync"
            @click="onlineStatusSyncClick()"
          >运行状态同步</el-button>
        </template>
        <el-table-column prop="resourceName" label="名称" width="280" />
        <el-table-column prop="devSerialNum" label="序列号" />
        <el-table-column prop="manufacturer" label="生产厂商" width="100" />
        <el-table-column prop="treatyType" label="接入协议" :formatter="treatyTypeFormatter" width="100" />
        <el-table-column prop="companyName" label="所属学校" />
        <el-table-column prop="collectTime" label="最近采集时间" :formatter="collectTimeFormatter" width="160" />
        <el-table-column prop="online" label="在线状态" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.online ===0"><span class="badge offline" />{{ '离线' }}</div>
            <div v-if="scope.row.online ===1"><span class="badge online" />{{ '在线' }}</div>
          </template>
        </el-table-column>
      </table-common>
    </y-card>
  </div>
</template>

<script>
import BaseNotify from '@/libs/mixin/BaseNotify'
import { dataSync, onlineStatusSync } from '@/api/resource/vcr/vcr'
import { mapGetters } from 'vuex'
export default {
  name: 'user',
  mixins: [BaseNotify],
  data() {
    return {
      queryParams: {
        companyName: '',
        resourceName: ''
      },
      treatyType: {
        hiksdk_net: '海康SDK',
        gb_reg: 'GB/T28181',
        ehome_reg: 'EHOME',
        onvif_net: 'ONVIF',
        dhsdk_net: '大华SDK',
        bi_reg: 'B接口协议',
        ezviz_net: '萤石协议'
      },
      loadingForDataSync: false,
      loadingForOnlineStatusSync: false
    }
  },
  computed: {
    ...mapGetters([
      'hasRole'
    ])
  },
  mounted() {

  },
  methods: {
    search() {
      this.$refs.table.reload(true)
    },
    reload() {
      this.$refs.table.reload()
    },
    reset() {
      this.$refs.searchForm.resetFields()
    },

    collectTimeFormatter(row, column, cellValue, index) {
      return cellValue || '-'
    },
    treatyTypeFormatter(row, column, cellValue, index) {
      return this.treatyType[cellValue]
    },

    dataSyncClick() {
      this.loadingForDataSync = true
      dataSync().then(res => {
        this.$message({
          message: '同步成功',
          type: 'success'
        })
        this.loadingForDataSync = false
      }).catch(_ => {
        this.loadingForDataSync = false
      })
    },
    onlineStatusSyncClick() {
      this.loadingForOnlineStatusSync = true
      onlineStatusSync().then(res => {
        this.$message({
          message: '同步成功',
          type: 'success'
        })
        this.loadingForOnlineStatusSync = false
      }).catch(_ => {
        this.loadingForOnlineStatusSync = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    margin-right: 5px;

}

.offline {
  background-color: #909399;
}

.online {
  background-color: #67c23a;
}

</style>
