<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <div class="title-container">
        <h3 class="title">明厨亮灶后台管理系统</h3>
      </div>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          placeholder="Username"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="off"
          prefix-icon="el-icon-user-solid"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          placeholder="Password"
          name="password"
          type="text"
          tabindex="2"
          autocomplete="off"
          prefix-icon="el-icon-lock"
          show-password
        />
      </el-form-item>
      <el-button :loading="loading" type="primary" class="login-button" @click.native.prevent="login">登 录</el-button>
    </el-form>
  </div>
</template>

<script>
import { login } from '@/api/main'

export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loading: false
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          login(this.loginForm).then(res => {
            this.$store.commit('setToken', res.data.accessToken)
            this.$store.commit('setRefreshToken', res.data.refreshToken)
            this.$store.dispatch('authorization').then(() => {
              this.$router.push({ path: process.env.VUE_APP_HOME_PATH })
            })
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.login-container {
  min-height: 100vh;
  width: 100%;
  background-color: #2d3a4b;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    padding: 160px 35px 0;
    overflow: hidden;
    box-sizing: border-box;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #fff;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }

  .el-input {
    display: inline-block;
    height: 47px;
    width: 95%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 45px;
      color: #fff;
      height: 47px;
      caret-color: #fff;
      font-family: sans-serif;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #283443 inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }

  i {
    font-size: 22px;
    padding: 2px 2px;
  }

  .el-input__icon {
    font-size: 22px;
    padding: 2px 2px;
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }

  .login-button{
    width:100%;
    margin-bottom:30px;
    font-size: 18px;
    line-height: 26px;
  }
}
</style>
