<template>
  <div class="c-root" :style="{'background-image': `url(${require('@/assets/camera/background.png')})`}">
    <div class="c-top-banner">
      <div class="c-school-name" :style="{'background-image': `url(${require('@/assets/common/top-left.png')})`}">{{ schoolName }}</div>
      <div class="c-title" :style="{'background-image': `url(${require('@/assets/common/top-center.png')})`}">互联网+明厨亮灶监管平台 监控大屏</div>
      <div class="c-time" :style="{'background-image': `url(${require('@/assets/common/top-right.png')})`}">{{ now + " " + week }}</div>
    </div>
    <div class="c-contro">
      <div class="c-contro-btn" :style="{'background-image': `url(${require('@/assets/camera/btn-1.png')})`}" @click="switchMode(1)" />
      <div class="c-contro-btn" :style="{'background-image': `url(${require('@/assets/camera/btn-4.png')})`}" @click="switchMode(4)" />
      <div class="c-contro-btn" :style="{'background-image': `url(${require('@/assets/camera/btn-9.png')})`}" @click="switchMode(9)" />
      <div class="c-contro-btn" :style="{'background-image': `url(${require('@/assets/camera/reply.png')})`}" @click="replay" />
    </div>
    <div class="c-content">
      <div v-for="index in mode" :key="index" class="c-video" :class="[{'c-current-box': currentIndex === index}, 'c-video-' + mode]">
        <Video ref="videoBox" :index="index" @select="select" />
      </div>
    </div>
    <replay :dialog-visible.sync="replyVisible" :device-index-code="currentCode" />
    <div class="toolbar" :style="{'background-image': `url(${require('@/assets/common/14x300.png')})`}" @click="toggle" />
    <Menu ref="menu" current="video" @evt="menuEvt" />
  </div>
</template>

<script>
import BaseNotify from '@/libs/mixin/BaseNotify'
import { schoolDetail } from '@/api/school/school'
import { cameraList, previewUrl } from '@/api/camera/camera'
import replay from './component/replay.vue'
import Menu from '../edu/component/Menu.vue'
import Video from './component/Video.vue'
export default {
  components: {
    Menu,
    Video,
    replay
  },
  mixins: [BaseNotify],
  props: {
    school: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mode: 1,
      now: '',
      week: '',
      currentCode: '',
      currentIndex: -1,
      replyVisible: false,
      schoolName: ''
    }
  },
  mounted() {
    const date = new Date()
    this.now = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
    this.week = '星期' + '日一二三四五六'.charAt(new Date().getDay())
    this.refresh5Min()
    // 以下5分钟刷新
    setInterval(this.refresh5Min, 5 * 60 * 1000)
    this.$nextTick(_ => {
      this.switchMode(this.mode)
    })
    schoolDetail(this.school).then(res => {
      this.schoolName = res.data.companyName
    })
  },
  methods: {
    switchMode(val) {
      this.mode = val
      cameraList(this.school).then(res => {
        this.$nextTick(async _ => {
          for (var i = 0; i < this.mode; i++) {
            if (res.data[i]) {
              const code = res.data[i].deviceIndexCode
              const preview = await previewUrl(code)
              // let isUrl= window.location.href;
              // if(document.location.protocol ){
              // }
              //preview.data?preview.data.replace(preview.data[1],'s'):preview.data
              this.$refs.videoBox[i].init(preview.data, code)
            }
          }
        })
      })
    },
    toggle() {
      this.show = !this.show
      this.$refs.menu.toggle(this.show)
    },
    async menuEvt(evtName) {
      this.show = false
      if (evtName === 'today') {
        await this.closeVideo()
        this.$router.push({ path: '/school-dashboard', query: { school: this.school }})
      } else if (evtName === 'dashboard') {
        await this.closeVideo()
        this.$router.push({ path: '/edu-dashboard' })
      } else if (evtName === 'back') {
        await this.closeVideo()
        this.$router.push({ path: '/edu-dashboard' })
      }
    },
    closeVideo() {
      const promiseList = []
      for (var i = 0; i < this.mode; i++) {
        const p = this.$refs.videoBox[i].close()
        if (p) {
          console.log('push')
          promiseList.push(p)
        }
      }
      return Promise.all(promiseList)
    },
    select(code, index) {
      this.currentCode = code
      this.currentIndex = index
    },
    replay() {
      if (!this.currentCode) {
        this.warningTip('请选择具体摄像头再进行回放操作')
        return
      }
      this.replyVisible = true
    },
    refresh5Min() {
      const date = new Date()
      this.now = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
      this.week = '星期' + '日一二三四五六'.charAt(new Date().getDay())
      console.log('刷新5分钟')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-root {
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  color: white;
  font-family: 思源黑体;
  font-weight: bold;
}
.c-top-banner {
  height: calc(10vh);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  .c-school-name {
    height: 60px;
    width: 400px;
    line-height: 40px;
  }
  .c-title {
    height: 68px;
    width: 1038px;
    line-height: 68px;
    font-size: 20px;
    font-weight: bold;
  }
  .c-time {
    height: 45px;
    width: 250px;
    line-height: 45px;
  }
}
.c-contro {
  position: absolute;
  left: 36px;
  top: 150px;
  .c-contro-btn {
    height: 56px;
    width: 56px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
.c-content {
  width: calc(80vw);
  height: calc(85vh);
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}
.c-video {
  border: 6px solid #0004e7;
  border-radius: 5px;
  background: #000000;
  box-sizing: border-box;
}
.c-current-box {
  border: 6px solid #0e5dfb;
}
.c-video-1 {
  width: 100%;
  height: 100%;
}
.c-video-4 {
  width: calc(50% - 25px);
  height: calc(50% - 25px);
  margin: 0px 20px 20px 0px;
}

.c-video-9 {
  width: calc(33% - 15px);
  height: calc(33% - 15px);
  margin: 0px 10px 10px 0px;
}
.toolbar {
  height: 300px;
  width: 14px;
  position: absolute;
  top: 150px;
  right: 0px;
  cursor: pointer;
}
</style>
