import request from '@/axios'

/**
 * 获取学校下的摄像头列表
 * @param {*} school
 * @returns
 */
export const cameraList = (school) => {
  return request({
    url: `/company/${school}/cameraList`,
    method: 'get'
  })
}

/**
 * 获取摄像头取流地址
 * @param {*} deviceIndexCode
 * @returns
 */
export const previewUrl = (deviceIndexCode) => {
  return request({
    url: `/camera/${deviceIndexCode}/previewUrl`,
    method: 'get'
  })
}

/**
 * 获取摄像头回放地址
 * @param {*} deviceIndexCode
 * @returns
 */
export const cameraReplay = (deviceIndexCode, beginTime, endTime) => {
  return request({
    url: `/camera/${deviceIndexCode}/playbackUrl`,
    method: 'get',
    params: {
      beginTime,
      endTime
    }
  })
}
