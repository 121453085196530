<template>
  <div v-loading="loading" class="player">
    <div :id="'player' + index" class="player" />
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      player: null,
      loading: false
    }
  },
  methods: {
    close() {
      if (this.player) {
        return new Promise((resolve, reject) => {
          this.player.JS_Stop(0).then(res => {
            this.loading = true
            this.player = null
            console.log('关闭成功')
            resolve()
          }).catch(res => {
            console.log('关闭失败')
            reject()
          })
        })
      }
      return null
    },
    init(url, code) {
      this.loading = true
      // eslint-disable-next-line no-undef
      this.player = new JSPlugin({
        // 需要英文字母开头 必填
        szId: 'player' + this.index,
        // 必填,引用H5player.min.js的js相对路径
        szBasePath: '/static/js/',

        // 分屏播放，默认最大分屏4*4
        iMaxSplit: 1,
        iCurrentSplit: 1

        // 样式
        // oStyle: {
        //   border: '#343434',
        //   borderSelect: '#FFCC00',
        //   background: '#000'
        // }
      })
      // 事件回调绑定
      this.player.JS_SetWindowControlCallback({
        windowEventSelect: (iWndIndex) => { // 插件选中窗口回调
          // console.log('windowSelect callback: ', iWndIndex)
          this.$emit('select', code, this.index)
        },
        pluginErrorHandler: (index, iErrorCode, oError) => {
          // 插件错误回调
          // do you want...
          // 取流失败，流中断等错误都会触发该回调函数，请自行对照错误码表进行判断。
          // 业务上层可在此做一些个性化操作，如：个性化错误信息展示，重新取流等。
          console.log('取流失败')
          this.loading = false
        },
        performanceLack: () => { // 性能不足回调
          console.log('performanceLack callback: ')
        }
      })

      this.play(url)
    },
    play(url) {
      const param = {
        playURL: url,
        mode: 1
      }
      this.player.JS_Play(url, param, 0).then(() => {
        console.log('播放成功')
        this.loading = false
      }).catch(err => {
        console.log('播放失败', err)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.player {
  height: 100%;
  width: 100%;
}
</style>
