<template>
  <div />
</template>

<script>
export default {
  name: 'home',
  mounted() {
  }
}
</script>

<style>
</style>
